/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { ConnectionContractStateViewModelPageR } from '../model/connectionContractStateViewModelPageR';
// @ts-ignore
import { ContractMutationState } from '../model/contractMutationState';
// @ts-ignore
import { ContractMutationStateWithContractStateTransitionsR } from '../model/contractMutationStateWithContractStateTransitionsR';
// @ts-ignore
import { ContractStateCountWithCountPerTransitionStateR } from '../model/contractStateCountWithCountPerTransitionStateR';
// @ts-ignore
import { ContractStateTransitionType } from '../model/contractStateTransitionType';
// @ts-ignore
import { ContractStateTransitionUrgency } from '../model/contractStateTransitionUrgency';
// @ts-ignore
import { Tenant } from '../model/tenant';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ConnectionContractStateService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param contractId 
     * @param connectionId 
     * @param contractMutationState 
     * @param contractStateTransitionType 
     * @param contractStateTransitionRemarks 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @deprecated
     */
    public apiAdminConnectionContractStateAppendTransitionRemarksPut(contractId?: string, connectionId?: string, contractMutationState?: ContractMutationState, contractStateTransitionType?: ContractStateTransitionType, contractStateTransitionRemarks?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public apiAdminConnectionContractStateAppendTransitionRemarksPut(contractId?: string, connectionId?: string, contractMutationState?: ContractMutationState, contractStateTransitionType?: ContractStateTransitionType, contractStateTransitionRemarks?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminConnectionContractStateAppendTransitionRemarksPut(contractId?: string, connectionId?: string, contractMutationState?: ContractMutationState, contractStateTransitionType?: ContractStateTransitionType, contractStateTransitionRemarks?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminConnectionContractStateAppendTransitionRemarksPut(contractId?: string, connectionId?: string, contractMutationState?: ContractMutationState, contractStateTransitionType?: ContractStateTransitionType, contractStateTransitionRemarks?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (contractId !== undefined && contractId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>contractId, 'contractId');
        }
        if (connectionId !== undefined && connectionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>connectionId, 'connectionId');
        }
        if (contractMutationState !== undefined && contractMutationState !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>contractMutationState, 'contractMutationState');
        }
        if (contractStateTransitionType !== undefined && contractStateTransitionType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>contractStateTransitionType, 'contractStateTransitionType');
        }
        if (contractStateTransitionRemarks !== undefined && contractStateTransitionRemarks !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>contractStateTransitionRemarks, 'contractStateTransitionRemarks');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/admin/ConnectionContractState/AppendTransitionRemarks`,
            null,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param contractId 
     * @param connectionId 
     * @param contractMutationState 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @deprecated
     */
    public apiAdminConnectionContractStateBlockConnectionContractPut(contractId?: string, connectionId?: string, contractMutationState?: ContractMutationState, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public apiAdminConnectionContractStateBlockConnectionContractPut(contractId?: string, connectionId?: string, contractMutationState?: ContractMutationState, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminConnectionContractStateBlockConnectionContractPut(contractId?: string, connectionId?: string, contractMutationState?: ContractMutationState, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminConnectionContractStateBlockConnectionContractPut(contractId?: string, connectionId?: string, contractMutationState?: ContractMutationState, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (contractId !== undefined && contractId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>contractId, 'contractId');
        }
        if (connectionId !== undefined && connectionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>connectionId, 'connectionId');
        }
        if (contractMutationState !== undefined && contractMutationState !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>contractMutationState, 'contractMutationState');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/admin/ConnectionContractState/BlockConnectionContract`,
            null,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param contractId 
     * @param connectionId 
     * @param contractMutationState 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @deprecated
     */
    public apiAdminConnectionContractStateCloseConnectionContractPut(contractId?: string, connectionId?: string, contractMutationState?: ContractMutationState, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public apiAdminConnectionContractStateCloseConnectionContractPut(contractId?: string, connectionId?: string, contractMutationState?: ContractMutationState, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminConnectionContractStateCloseConnectionContractPut(contractId?: string, connectionId?: string, contractMutationState?: ContractMutationState, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminConnectionContractStateCloseConnectionContractPut(contractId?: string, connectionId?: string, contractMutationState?: ContractMutationState, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (contractId !== undefined && contractId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>contractId, 'contractId');
        }
        if (connectionId !== undefined && connectionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>connectionId, 'connectionId');
        }
        if (contractMutationState !== undefined && contractMutationState !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>contractMutationState, 'contractMutationState');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/admin/ConnectionContractState/CloseConnectionContract`,
            null,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param contractId 
     * @param connectionId 
     * @param contractMutationState 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @deprecated
     */
    public apiAdminConnectionContractStateCloseConnectionContractTransitionPut(contractId?: string, connectionId?: string, contractMutationState?: ContractMutationState, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public apiAdminConnectionContractStateCloseConnectionContractTransitionPut(contractId?: string, connectionId?: string, contractMutationState?: ContractMutationState, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminConnectionContractStateCloseConnectionContractTransitionPut(contractId?: string, connectionId?: string, contractMutationState?: ContractMutationState, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminConnectionContractStateCloseConnectionContractTransitionPut(contractId?: string, connectionId?: string, contractMutationState?: ContractMutationState, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (contractId !== undefined && contractId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>contractId, 'contractId');
        }
        if (connectionId !== undefined && connectionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>connectionId, 'connectionId');
        }
        if (contractMutationState !== undefined && contractMutationState !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>contractMutationState, 'contractMutationState');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/admin/ConnectionContractState/CloseConnectionContractTransition`,
            null,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @deprecated
     */
    public apiAdminConnectionContractStateContractMutationStatesWithContractStateTransitionsGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ContractMutationStateWithContractStateTransitionsR>>;
    public apiAdminConnectionContractStateContractMutationStatesWithContractStateTransitionsGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ContractMutationStateWithContractStateTransitionsR>>>;
    public apiAdminConnectionContractStateContractMutationStatesWithContractStateTransitionsGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ContractMutationStateWithContractStateTransitionsR>>>;
    public apiAdminConnectionContractStateContractMutationStatesWithContractStateTransitionsGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<ContractMutationStateWithContractStateTransitionsR>>(`${this.configuration.basePath}/api/admin/ConnectionContractState/ContractMutationStatesWithContractStateTransitions`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param contractId 
     * @param contractMutationState 
     * @param contractStateTransitionType 
     * @param tenant 
     * @param isActive 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @deprecated
     */
    public apiAdminConnectionContractStateCountPerContractMutationStateAndContractStateTransitionTypeGet(contractId?: Array<string>, contractMutationState?: Array<ContractMutationState>, contractStateTransitionType?: Array<ContractStateTransitionType>, tenant?: Array<Tenant>, isActive?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ContractStateCountWithCountPerTransitionStateR>>;
    public apiAdminConnectionContractStateCountPerContractMutationStateAndContractStateTransitionTypeGet(contractId?: Array<string>, contractMutationState?: Array<ContractMutationState>, contractStateTransitionType?: Array<ContractStateTransitionType>, tenant?: Array<Tenant>, isActive?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ContractStateCountWithCountPerTransitionStateR>>>;
    public apiAdminConnectionContractStateCountPerContractMutationStateAndContractStateTransitionTypeGet(contractId?: Array<string>, contractMutationState?: Array<ContractMutationState>, contractStateTransitionType?: Array<ContractStateTransitionType>, tenant?: Array<Tenant>, isActive?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ContractStateCountWithCountPerTransitionStateR>>>;
    public apiAdminConnectionContractStateCountPerContractMutationStateAndContractStateTransitionTypeGet(contractId?: Array<string>, contractMutationState?: Array<ContractMutationState>, contractStateTransitionType?: Array<ContractStateTransitionType>, tenant?: Array<Tenant>, isActive?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (contractId) {
            contractId.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'contractId');
            })
        }
        if (contractMutationState) {
            contractMutationState.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'contractMutationState');
            })
        }
        if (contractStateTransitionType) {
            contractStateTransitionType.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'contractStateTransitionType');
            })
        }
        if (tenant) {
            tenant.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'tenant');
            })
        }
        if (isActive !== undefined && isActive !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isActive, 'isActive');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<ContractStateCountWithCountPerTransitionStateR>>(`${this.configuration.basePath}/api/admin/ConnectionContractState/CountPerContractMutationStateAndContractStateTransitionType`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param offset 
     * @param limit 
     * @param contractId 
     * @param contractMutationState 
     * @param contractStateTransitionType 
     * @param contractStateTransitionUrgency 
     * @param assignee 
     * @param tenant 
     * @param isActive 
     * @param connectionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @deprecated
     */
    public apiAdminConnectionContractStateGet(offset?: number, limit?: number, contractId?: Array<string>, contractMutationState?: Array<ContractMutationState>, contractStateTransitionType?: Array<ContractStateTransitionType>, contractStateTransitionUrgency?: Array<ContractStateTransitionUrgency>, assignee?: Array<string>, tenant?: Array<Tenant>, isActive?: boolean, connectionId?: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ConnectionContractStateViewModelPageR>;
    public apiAdminConnectionContractStateGet(offset?: number, limit?: number, contractId?: Array<string>, contractMutationState?: Array<ContractMutationState>, contractStateTransitionType?: Array<ContractStateTransitionType>, contractStateTransitionUrgency?: Array<ContractStateTransitionUrgency>, assignee?: Array<string>, tenant?: Array<Tenant>, isActive?: boolean, connectionId?: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ConnectionContractStateViewModelPageR>>;
    public apiAdminConnectionContractStateGet(offset?: number, limit?: number, contractId?: Array<string>, contractMutationState?: Array<ContractMutationState>, contractStateTransitionType?: Array<ContractStateTransitionType>, contractStateTransitionUrgency?: Array<ContractStateTransitionUrgency>, assignee?: Array<string>, tenant?: Array<Tenant>, isActive?: boolean, connectionId?: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ConnectionContractStateViewModelPageR>>;
    public apiAdminConnectionContractStateGet(offset?: number, limit?: number, contractId?: Array<string>, contractMutationState?: Array<ContractMutationState>, contractStateTransitionType?: Array<ContractStateTransitionType>, contractStateTransitionUrgency?: Array<ContractStateTransitionUrgency>, assignee?: Array<string>, tenant?: Array<Tenant>, isActive?: boolean, connectionId?: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (offset !== undefined && offset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }
        if (contractId) {
            contractId.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'contractId');
            })
        }
        if (contractMutationState) {
            contractMutationState.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'contractMutationState');
            })
        }
        if (contractStateTransitionType) {
            contractStateTransitionType.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'contractStateTransitionType');
            })
        }
        if (contractStateTransitionUrgency) {
            contractStateTransitionUrgency.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'contractStateTransitionUrgency');
            })
        }
        if (assignee) {
            assignee.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'assignee');
            })
        }
        if (tenant) {
            tenant.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'tenant');
            })
        }
        if (isActive !== undefined && isActive !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isActive, 'isActive');
        }
        if (connectionId) {
            connectionId.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'connectionId');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<ConnectionContractStateViewModelPageR>(`${this.configuration.basePath}/api/admin/ConnectionContractState`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param tenant 
     * @param groupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @deprecated
     */
    public apiAdminConnectionContractStateGetMostRecentlySentWelcomeLetterGet(tenant?: Tenant, groupId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf', context?: HttpContext}): Observable<Blob>;
    public apiAdminConnectionContractStateGetMostRecentlySentWelcomeLetterGet(tenant?: Tenant, groupId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public apiAdminConnectionContractStateGetMostRecentlySentWelcomeLetterGet(tenant?: Tenant, groupId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public apiAdminConnectionContractStateGetMostRecentlySentWelcomeLetterGet(tenant?: Tenant, groupId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/pdf', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (tenant !== undefined && tenant !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant, 'tenant');
        }
        if (groupId !== undefined && groupId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>groupId, 'groupId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/pdf'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        return this.httpClient.get(`${this.configuration.basePath}/api/admin/ConnectionContractState/GetMostRecentlySentWelcomeLetter`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param tenant 
     * @param groupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @deprecated
     */
    public apiAdminConnectionContractStateGetStartDeliveryLetterGet(tenant?: Tenant, groupId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf', context?: HttpContext}): Observable<Blob>;
    public apiAdminConnectionContractStateGetStartDeliveryLetterGet(tenant?: Tenant, groupId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public apiAdminConnectionContractStateGetStartDeliveryLetterGet(tenant?: Tenant, groupId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public apiAdminConnectionContractStateGetStartDeliveryLetterGet(tenant?: Tenant, groupId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/pdf', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (tenant !== undefined && tenant !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant, 'tenant');
        }
        if (groupId !== undefined && groupId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>groupId, 'groupId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/pdf'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        return this.httpClient.get(`${this.configuration.basePath}/api/admin/ConnectionContractState/GetStartDeliveryLetter`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminConnectionContractStateHelloGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<string>>;
    public apiAdminConnectionContractStateHelloGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<string>>>;
    public apiAdminConnectionContractStateHelloGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<string>>>;
    public apiAdminConnectionContractStateHelloGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<string>>(`${this.configuration.basePath}/api/admin/ConnectionContractState/Hello`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param tenant 
     * @param groupId 
     * @param shouldRecalculateAdvanceAmount 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @deprecated
     */
    public apiAdminConnectionContractStateResendWelcomeLetterPut(tenant?: Tenant, groupId?: string, shouldRecalculateAdvanceAmount?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf', context?: HttpContext}): Observable<Blob>;
    public apiAdminConnectionContractStateResendWelcomeLetterPut(tenant?: Tenant, groupId?: string, shouldRecalculateAdvanceAmount?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public apiAdminConnectionContractStateResendWelcomeLetterPut(tenant?: Tenant, groupId?: string, shouldRecalculateAdvanceAmount?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public apiAdminConnectionContractStateResendWelcomeLetterPut(tenant?: Tenant, groupId?: string, shouldRecalculateAdvanceAmount?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/pdf', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (tenant !== undefined && tenant !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant, 'tenant');
        }
        if (groupId !== undefined && groupId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>groupId, 'groupId');
        }
        if (shouldRecalculateAdvanceAmount !== undefined && shouldRecalculateAdvanceAmount !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>shouldRecalculateAdvanceAmount, 'shouldRecalculateAdvanceAmount');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/pdf'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        return this.httpClient.put(`${this.configuration.basePath}/api/admin/ConnectionContractState/ResendWelcomeLetter`,
            null,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param contractId 
     * @param connectionId 
     * @param contractMutationState 
     * @param contractStateTransitionType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @deprecated
     */
    public apiAdminConnectionContractStateSelfAssignTransitionPut(contractId?: string, connectionId?: string, contractMutationState?: ContractMutationState, contractStateTransitionType?: ContractStateTransitionType, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public apiAdminConnectionContractStateSelfAssignTransitionPut(contractId?: string, connectionId?: string, contractMutationState?: ContractMutationState, contractStateTransitionType?: ContractStateTransitionType, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminConnectionContractStateSelfAssignTransitionPut(contractId?: string, connectionId?: string, contractMutationState?: ContractMutationState, contractStateTransitionType?: ContractStateTransitionType, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminConnectionContractStateSelfAssignTransitionPut(contractId?: string, connectionId?: string, contractMutationState?: ContractMutationState, contractStateTransitionType?: ContractStateTransitionType, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (contractId !== undefined && contractId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>contractId, 'contractId');
        }
        if (connectionId !== undefined && connectionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>connectionId, 'connectionId');
        }
        if (contractMutationState !== undefined && contractMutationState !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>contractMutationState, 'contractMutationState');
        }
        if (contractStateTransitionType !== undefined && contractStateTransitionType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>contractStateTransitionType, 'contractStateTransitionType');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/admin/ConnectionContractState/SelfAssignTransition`,
            null,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param tenant 
     * @param groupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @deprecated
     */
    public apiAdminConnectionContractStateSendStartDeliveryLetterPut(tenant?: Tenant, groupId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf', context?: HttpContext}): Observable<Blob>;
    public apiAdminConnectionContractStateSendStartDeliveryLetterPut(tenant?: Tenant, groupId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public apiAdminConnectionContractStateSendStartDeliveryLetterPut(tenant?: Tenant, groupId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public apiAdminConnectionContractStateSendStartDeliveryLetterPut(tenant?: Tenant, groupId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/pdf', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (tenant !== undefined && tenant !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant, 'tenant');
        }
        if (groupId !== undefined && groupId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>groupId, 'groupId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/pdf'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        return this.httpClient.put(`${this.configuration.basePath}/api/admin/ConnectionContractState/SendStartDeliveryLetter`,
            null,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param contractId 
     * @param connectionId 
     * @param contractMutationState 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @deprecated
     */
    public apiAdminConnectionContractStateUnblockConnectionContractPut(contractId?: string, connectionId?: string, contractMutationState?: ContractMutationState, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public apiAdminConnectionContractStateUnblockConnectionContractPut(contractId?: string, connectionId?: string, contractMutationState?: ContractMutationState, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminConnectionContractStateUnblockConnectionContractPut(contractId?: string, connectionId?: string, contractMutationState?: ContractMutationState, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminConnectionContractStateUnblockConnectionContractPut(contractId?: string, connectionId?: string, contractMutationState?: ContractMutationState, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (contractId !== undefined && contractId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>contractId, 'contractId');
        }
        if (connectionId !== undefined && connectionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>connectionId, 'connectionId');
        }
        if (contractMutationState !== undefined && contractMutationState !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>contractMutationState, 'contractMutationState');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/admin/ConnectionContractState/UnblockConnectionContract`,
            null,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
