<div class="row">
    <div class="col">
        <span class="table-title">Geschiedenis</span><br>
        <br>

        <table class="table-front">
            <thead>
                <tr>
                    <th> ProfielCode </th>
                    <th> Van </th>
                    <th> Tot </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let connectionProfile of connectionProfiles">
                    <td> {{connectionProfile.ProfileId}} </td>
                    <td> {{connectionProfile.Interval.From | date: 'dd-MM-yyyy'}} </td>
                    <td> {{connectionProfile.Interval.Until | date: 'dd-MM-yyyy'}} </td>
                </tr>
            </tbody>
        </table>

    </div>
</div>