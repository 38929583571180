import { ConnectionPhysicalState } from 'src/app/Connection';

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-connection-physical-state-list',
  templateUrl: './connection-physical-state-list.component.html',
  styleUrls: ['./connection-physical-state-list.component.scss'],
})
export class ConnectionPhysicalStateListComponent implements OnInit {
  @Input() connectionPhysicalStates: ConnectionPhysicalState[];

  public hasHistory: boolean;
  public isToggled: boolean;

  constructor() {}

  ngOnInit(): void {
    this.hasHistory = this.connectionPhysicalStates.length > 1;
    this.connectionPhysicalStates = this.connectionPhysicalStates.sort((x, y) => (x.Interval.From > y.Interval.From ? 1 : -1));

    if (this.hasHistory) {
      const foundIdx = this.connectionPhysicalStates.findIndex((el) => el.Interval.Until === null);
      const item = this.connectionPhysicalStates[foundIdx];
      this.connectionPhysicalStates.splice(foundIdx, 1);
      this.connectionPhysicalStates.unshift(item);
    }
  }

  toggleHistory() {
    this.isToggled = !this.isToggled;
  }
}
