import { ConnectionSupplyType } from 'src/app/Connection';

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-connection-type-list',
  templateUrl: './connection-type-list.component.html',
  styleUrls: ['./connection-type-list.component.scss'],
})
export class ConnectionTypeListComponent implements OnInit {
  @Input() connectionSupplyTypes: ConnectionSupplyType[];

  public hasHistory: boolean;
  public isToggled: boolean;

  constructor() {}

  ngOnInit(): void {
    this.hasHistory = this.connectionSupplyTypes.length > 1;
    this.connectionSupplyTypes = this.connectionSupplyTypes.sort((x, y) => (x.Interval.From > y.Interval.From ? 1 : -1));

    if (this.hasHistory) {
      const foundIdx = this.connectionSupplyTypes.findIndex((el) => el.Interval.Until === null);
      const item = this.connectionSupplyTypes[foundIdx];
      this.connectionSupplyTypes.splice(foundIdx, 1);
      this.connectionSupplyTypes.unshift(item);
    }
  }

  toggleHistory() {
    this.isToggled = !this.isToggled;
  }
}
