import moment, { Moment } from 'moment';
import { EnumDescription, Issue, IssueCountPerIssueTypeAndSubject, Page } from 'src/app/Connection';
import { NEWLINE } from 'src/app/constants/csv';
import { entries, groupBy } from 'src/app/helpers/ArrayHelper';
import { BlobCreateHelper } from 'src/app/helpers/BlobCreateHelper';
import { ConnectionService } from 'src/app/services/connection.service';
import { ErrorService } from 'src/app/services/ErrorService';
import { IssueService } from 'src/app/services/issue.service';
import { TenantService } from 'src/app/services/tenant.service';
import { TimeTools } from 'src/app/services/time-tools.service';
import { TranslationFromMemoryOrApiService } from 'src/app/services/translation-from-memory-or-api.service';

import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ITranslationCategory } from '../../TranslationDashboard/TranslationCategory';

@Component({
  selector: 'issues-overview-component',
  templateUrl: './IssueOverviewComponent.html',
  styleUrls: ['./IssueOverviewComponent.scss'],
})
export class IssueOverviewComponent implements OnInit {
  selectedRow: number;
  setClickedRow;
  issueCounts: any;
  issues: Page<Issue>;
  issuesTypes: any[];
  selectedIssues: any[] = [];
  connections: any[] = [];
  connectionViews: any[] = [];
  connectionService: ConnectionService;
  tenantService: TenantService;
  searchString: string;
  pageNumbers: number[] = [];
  pageSize = 15;
  subject?: string;
  currentPage = 0;
  thisPage = 1;
  openIssueTypes: string[] = [];
  open = true; // Default value of Issue  open, thus only showing open issues at the first
  modifiedSince: Moment;
  modifiedSinceControl: UntypedFormControl;
  modifiedAfter: Moment;
  modifiedBefore: Moment;
  issueIds: number[] = [];
  issueIdsForRouter: number[] = [];
  isShowFilters = false;
  activeModal = false;

  tenantWarning: boolean;
  closeWarning = false;

  isLoading: boolean;

  IssueTypeEnums: EnumDescription[];

  startPeriod: Moment;
  endPeriod: Moment;

  voorbeeld = 'tot';

  issueCountsTotal: number;

  commatype: string; // , or ;
  showDownloadExplanation = false;
  numberOfEans: number;
  form: UntypedFormGroup;

  issueUrlTranslations: ITranslationCategory;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translation: TranslationFromMemoryOrApiService,
    connectionService: ConnectionService,
    private issueService: IssueService,
    tenantService: TenantService,
    private errorService: ErrorService,
    private timeTools: TimeTools,
  ) {
    this.connectionService = connectionService;
    this.tenantService = tenantService;
    this.setClickedRow = function (index) {
      this.selectedRow = index;
    };
  }

  async ngOnInit() {
    this.modifiedSinceControl = new UntypedFormControl(this.modifiedSince);
    await this.checkRouterParams();
    this.checkTenants();
    await this.fetchData();
    this.compareToYesterday();
  }

  checkRouterParams() {
    this.route.queryParamMap.subscribe((paramsMap: any) => {
      if (paramsMap.keys.includes('open')) {
        this.open = paramsMap.params['open'];
      }
      if (paramsMap.keys.includes('issueType')) {
        this.selectedIssues = [].concat(paramsMap.params['issueType']);
      }
      if (paramsMap.keys.includes('id')) {
        this.issueIds = paramsMap.params['id'].split(',');
      }
      if (paramsMap.keys.includes('search')) {
        this.searchString = paramsMap.params['search'];
      }
    });
  }

  async getIssues() {
    this.issues = null;
    await this.issueService
      .getAll(
        this.currentPage * this.pageSize,
        this.pageSize,
        this.searchString,
        this.open,
        this.selectedIssues,
        this.subject,
        this.modifiedSince,
        this.modifiedBefore,
        this.issueIds,
      )
      .then((issues) => (this.issues = issues))
      .then(() => this.calcPages(this.issues))
      .catch((e) => {
        this.errorService.addError(e.message);
        this.isLoading = false;
        throw new Error(`${e.message} : ${JSON.stringify(e.error)}`);
      });
    this.issueIds = []; // clear check route issueIds if they were present.
  }

  async fetchData() {
    this.isLoading = true;
    this.getIssueCountAndEnums();
    await this.getIssues();
    this.isLoading = false;
  }

  closeThisIssue(issue) {
    this.issueService
      .closeIssue(issue.Id)
      .then(() => alert('Issue gesloten'))
      .then(() => this.fetchData())
      .catch((e) => {
        new Error('Niet geluk: ' + e.error.title);
        throw new Error(`${e.message} : ${JSON.stringify(e.error)}`);
      });
  }

  async processIssue(id: number) {
    await this.issueService
      .processIssue(id)
      .then((result) => alert('Issue verwerkt'))
      .then(() => this.fetchData())
      .catch((error) => {
        throw new Error('Niet geluk: ' + error.error.title);
      });
  }

  checkTenants() {
    const tenants = this.tenantService.getCurrentTenantsFromLocalStorage();
    if (tenants.length === 1) {
      this.tenantWarning = true;
    }
  }

  picker(event: any) {
    this.modifiedSince = event.value;
    this.fetchData();
  }

  issueIsSelected(issue: any) {
    var index = this.selectedIssues.map((i) => i.IssueType).indexOf(issue.IssueType);
    return index > -1;
  }

  issueTypeSubjectIsToggled(issueType: string): boolean {
    return this.openIssueTypes.indexOf(issueType) > -1;
  }
  searchIssueTypeAndSubject(issueType: string, subject?: string) {
    this.addToRouter(issueType);
    this.selectedIssues = [issueType];
    this.subject = subject;
    this.currentPage = 0; // resets the page offset in API Call!
    this.getIssues();
  }

  addIssueIdToURL(issueId) {
    if (this.issueIdsForRouter == undefined) {
      return;
    }
    this.issueIdsForRouter.includes(issueId)
      ? this.issueIdsForRouter.splice(this.issueIdsForRouter.indexOf(issueId), 1)
      : this.issueIdsForRouter.push(issueId);
    // prevented history of the same issuetype (e.g. doubleclicked)
    let queryPath: string = ''; // might want to leave the rest intact?
    this.issueIdsForRouter.length != 0 ? (queryPath += '?id=' + this.issueIdsForRouter.join(',')) : (queryPath = '');
    history.pushState(null, '', location.pathname + queryPath);
  }

  isSelectedIssueId(issueId) {
    return this.issueIdsForRouter.includes(issueId);
  }

  addToRouter(issueTypes) {
    if (this.selectedIssues.includes(issueTypes)) {
      return;
    }
    // prevented history of the same issuetype (e.g. doubleclicked)
    let queryPath = '?issueType=' + issueTypes;
    history.pushState(null, '', location.pathname + queryPath);
  }

  toggleIssueTypeSubject(issueType: string) {
    if (this.openIssueTypes.find((s) => s === issueType)) {
      this.openIssueTypes.splice(this.openIssueTypes.indexOf(issueType), 1);
    } else {
      this.openIssueTypes.push(issueType);
    }
  }

  toggleIssue(issue: any) {
    var index = this.selectedIssues.map((i) => i.IssueType).indexOf(issue.IssueType);
    if (index > -1) {
      while (index > -1) {
        this.selectedIssues.splice(index);
        index = this.selectedIssues.map((i) => i.IssueType).indexOf(issue.IssueType);
      }
    } else {
      this.selectedIssues.push(issue);
      const queryPath = '?issueType=' + this.selectedIssues;
      history.pushState(null, '', location.pathname + queryPath);
    }

    localStorage['selectedIssues'] = JSON.stringify(this.selectedIssues);
  }

  calcPages(page: Page<any>) {
    this.pageNumbers = [];
    for (let i = 0; i < page.totalCount / this.pageSize; i++) {
      this.pageNumbers.push(i + 1);
    }
  }
  next() {
    if (this.currentPage > this.pageNumbers[this.pageNumbers.length - 1]) {
      this.page(this.currentPage - 1);
    }
  }
  previous() {
    if (this.currentPage > 0) {
      this.page(this.currentPage + 1);
    }
  }
  page(goToNumber: number) {
    this.currentPage = goToNumber - 1;
    this.getIssues();
  }

  searchEventlistener(event) {
    if (event.shortcut === true) {
      // shortcut option to quickly navigate using ctrl click
      // this.router.navigate(['/connection', event.searchString]);
    } else {
      this.onUpdateSearch(event.searchString);
    }
  }

  onUpdateSearch(searchString: string) {
    let queryPath = '?';
    if (searchString !== undefined) {
      queryPath = '?search=' + searchString;
    }
    if (this.open !== undefined) {
      queryPath = '?open=' + this.open;
    }
    this.searchString = searchString;
    this.selectedIssues.forEach((i) => (queryPath += '&issue=' + i.IssueType));
    history.pushState(null, '', location.pathname + queryPath);
    this.fetchData();
  }

  changeOnSelect(event) {
    // haal uit het event de waarde (uit de [value] die changed)
    this.open = event.value;

    let queryPath = '?';
    if (this.open !== undefined) {
      queryPath = '?open=' + this.open;
    }
    this.selectedIssues.forEach((i) => (queryPath += '&issue=' + i.IssueType));
    history.pushState(null, '', location.pathname + queryPath);
    this.fetchData();
  }

  async getIssueCountAndEnums() {
    this.issueCounts = null;
    const issueCounts = await this.issueService
      // .getIssueCountPerIssueTypeAndSubject(this.open, this.modifiedSince, this.searchString, this.modifiedBefore)
      .getIssueOverview(this.open, this.startPeriod, this.endPeriod)
      .then((apiResult) =>
        entries(groupBy(apiResult, (i: IssueCountPerIssueTypeAndSubject) => i.IssueType)).map((entry) => ({
          key: entry.key,
          value: entry.value,
          count: (entry.value as any)?.reduce((sum, current) => sum + current.Count, 0),
        })),
      )
      .catch((e) => {
        this.errorService.addError(e.message);
        this.isLoading = false;
        throw new Error(`${e.message} : ${JSON.stringify(e.error)}`);
      });
    this.issueCounts = issueCounts;
    this.issueCountsTotal = this.issueCounts?.reduce((sum, current) => sum + current.count, 0);
  }

  async compareToYesterday() {
    // Only runs at startup.
    const issueCountsOfYesterday = await this.issueService
      // .getIssueCountPerIssueTypeAndSubject(this.open, this.modifiedSince, this.searchString, this.modifiedBefore)
      .getIssueOverview(this.open, null, moment().subtract(1, 'days'))
      .then((apiResult) =>
        entries(groupBy(apiResult, (i: IssueCountPerIssueTypeAndSubject) => i.IssueType)).map((entry) => ({
          key: entry.key,
          value: entry.value,
          count: (entry.value as any)?.reduce((sum, current) => sum + current.Count, 0),
        })),
      )
      .catch((e) => {
        this.errorService.addError(e.message);
        throw new Error(`${e.message} : ${JSON.stringify(e.error)}`);
      });
    let list: any = issueCountsOfYesterday;
    const newIssueCounts = this.issueCounts?.map((ic: any) => {
      const yesterdaysIssueCount = list.filter((i: any) => i.key === ic.key).length > 0 ? list.filter((i: any) => i.key === ic.key)[0] : { count: 0 };
      const mutation = ic.count - yesterdaysIssueCount.count;
      ic.mutation = mutation;
      return ic;
    });
    // adds additional field to the current count, only once at startup.
    this.issueCounts = newIssueCounts;
  }

  goToConnection(issue: any) {
    const url: any = this.router.createUrlTree(['/connection', issue.ConnectionId]);
    window.open(url, '_blank');
  }

  showIssueContextList: any[] = [];
  // For the frontend, returns true or false to render active or inactive item.
  showIssueContextCheck(i: number) {
    return this.showIssueContextList.includes(i);
  }
  showIssueContext(i: number) {
    let index = this.showIssueContextList.indexOf(i);
    if (index > -1) {
      this.showIssueContextList.splice(index);
    } else {
      this.showIssueContextList.push(i);
    }
  }

  async changeissueTrendPeriod(selectedPeriod) {
    if (selectedPeriod === undefined || selectedPeriod === null) return;
    this.modifiedSince = selectedPeriod.from ? moment(selectedPeriod.from) : null; // TODO: Make issues createdFrom and Until -> Has to be used, since Issues call has no createdFrom and createdUntil
    this.modifiedBefore = selectedPeriod.until ? moment(selectedPeriod.until) : null;
    this.startPeriod = selectedPeriod.from ? moment(selectedPeriod.from) : null;
    this.endPeriod = selectedPeriod.until ? moment(selectedPeriod.until) : null;
    this.fetchData();
  }

  showFilters() {
    this.isShowFilters = !this.isShowFilters;
  }

  /// Download connections functionality
  downloadCSV(all: boolean, commatype: string) {
    this.commatype = commatype;
    var amountOfEans = all ? 20000 : prompt('Hoeveel issues moeten er in het document komen?', '500');
    this.showDownloadExplanation = true;
    if (amountOfEans == null) {
      return;
    }
    var numberOfEans: number = +amountOfEans; // string to number typesafe
    if (isNaN(numberOfEans)) {
      return this.errorService.addError('Voer geldig getal in');
    }
    if (!this.open) {
      return this.errorService.addError('Kan alleen exporteren voor openstaande issues');
    }
    this.fetchIssuesForExport(all, numberOfEans);
  }

  async fetchIssuesForExport(all: boolean, numberOfEans: number) {
    let selectedIssue = all ? null : this.selectedIssues;

    let pageddata: any = await this.issueService
      .getAll(0, numberOfEans, null, this.open, selectedIssue, this.subject, this.modifiedSince)
      .catch((e) => {
        this.errorService.addError('Kan geen data ophalen, foutmelding: ' + e.message);
        throw new Error(`${e.message} : ${JSON.stringify(e.error)}`);
      });

    if (pageddata === undefined || pageddata.length === 0 || pageddata.data.length === 0) {
      return this.errorService.addError('Kan geen CSV maken en downloaden');
    }
    let filename =
      'IssuesUitlijsting' +
      (this.selectedIssues ? '-' + this.selectedIssues : '') +
      (this.subject ? '-' + this.subject : '') +
      '-' +
      moment().format('YYYY-MM-DD');
    let csvData = await this.makeCSVData(pageddata.data);
    BlobCreateHelper.downloadCSV(filename, csvData);
  }

  async makeCSVData(data) {
    let csvData;
    let comma = this.commatype;
    let MultipleIssueTypesSelected: boolean = this.selectedIssues.length > 1; // no yet supported, but should be in future
    let issueTypesTranslated = this.selectedIssues.map((issueType) => this.translation.getTranslation('Frontend', 'Issues', issueType));

    let documentIntro =
      'Geëxporteerd op: ' +
      comma +
      moment().format('YYYY-MM-DD') +
      comma +
      NEWLINE +
      'Geselecteerd IssueType: ' +
      comma +
      // this.selectedIssues.map((i) => i) +
      issueTypesTranslated +
      NEWLINE +
      'Geselecteerd Onderwerp: ' +
      comma +
      (this.subject ? this.subject : '') +
      NEWLINE;
    let documentHeader =
      'IssueType' +
      comma +
      'Subject' +
      comma +
      'Issue Id' +
      comma +
      'EAN' +
      comma +
      'Vanaf' +
      comma +
      'Context van' +
      comma +
      'Context Tot' +
      comma +
      'Context' +
      NEWLINE;

    let rows = '';
    data.map((issue) => {
      let issueFrom = issue.From != undefined || issue.From != null ? issue.From.moment.format('YYYY-MM-DD H:mm') : '';
      let issueTypeTranslated = this.translation.getTranslation('Frontend', 'Issues', issue.IssueType);
      let ctxFrom = issue.ContextFrom != undefined || issue.ContextFrom != null ? issue.ContextFrom.format('YYYY-MM-DD H:mm') : '';
      let ctxUntil = issue.ContextUntil != undefined || issue.ContextUntil != null ? issue.ContextUntil.format('YYYY-MM-DD H:mm') : '';
      let ean = issue.ConnectionId != undefined ? '' + issue.ConnectionId : '';
      return (rows +=
        issueTypeTranslated +
        comma +
        issue.Subject +
        comma +
        issue.Id +
        comma +
        ean +
        comma +
        issueFrom +
        comma +
        ctxFrom +
        comma +
        ctxUntil +
        comma +
        'Nog niet beschikbaar' +
        NEWLINE);
    });
    csvData = documentIntro + documentHeader + rows;
    return csvData;
  }
}
