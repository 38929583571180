<div class="row">
    <div class="col">
        <span class="table-title">Geschiedenis</span><br>
        <br>

        <table class="table-front">
            <thead>
                <tr>
                    <th> Allocatiemethode </th>
                    <th> Van </th>
                    <th> Tot </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let allocationMethods of allocationMethod">
                    <td *ngIf="connectionInfo === 'KV' && allocationMethods.allocationMethod === 'Telemetric' else GV">
                        SMA</td>
                    <ng-template #GV>
                        <td>{{allocationMethods.allocationMethod}}</td>
                    </ng-template>
                    <td> {{allocationMethods.interval.From | date: 'dd-MM-yyyy'}} </td>
                    <td> {{allocationMethods.interval.Until | date: 'dd-MM-yyyy'}} </td>
                </tr>
            </tbody>
        </table>

    </div>
</div>