<div class="row row-top">
    <div class="col-12">
        <span class="table-title">Fysieke status</span>
        <table class="table-responsive table-front">
            <thead>
                <tr>
                    <th scope="col">Van</th>
                    <th>Tot</th>
                    <th>Status</th>
                    <th *ngIf="this.hasHistory">
                        <mat-icon class="maticon-button-table maticon-meter"
                            matTooltip="Toon geschiedenis van de status" (click)="toggleHistory()">
                            history
                        </mat-icon>
                    </th>
                </tr>
            </thead>
            <tbody>

                <tr *ngIf="this.hasHistory || connectionPhysicalStates.length === 1">
                    <td scope="row">{{connectionPhysicalStates[0]?.Interval?.From?.format('YYYY-MM-DD')}}</td>
                    <td>{{connectionPhysicalStates[0]?.Interval?.Until?.format('YYYY-MM-DD')}}</td>
                    <td>
                        <translation [name]="connectionPhysicalStates[0].PhysicalState">
                        </translation>
                    </td>
                    <td *ngIf="this.hasHistory"></td>
                </tr>

                <ng-template *ngIf="this.hasHistory && isToggled" ngFor let-connectionPhysicalState
                    [ngForOf]="connectionPhysicalStates.slice(1)" let-i="index">
                    <tr class="table-history-row">
                        <td scope="row">{{connectionPhysicalState?.Interval?.From?.format('YYYY-MM-DD')}}</td>
                        <td>{{connectionPhysicalState?.Interval?.Until?.format('YYYY-MM-DD')}}</td>
                        <td>
                            <translation [name]="connectionPhysicalState.PhysicalState">
                            </translation>
                        </td>
                        <td *ngIf="this.hasHistory"></td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
    </div>
</div>