import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-connection-profilecode-history',
  templateUrl: './connection-profilecode-history.component.html',
  styleUrls: ['./connection-profilecode-history.component.scss'],
})
export class ConnectionProfilecodeHistoryComponent implements OnInit {
  @Input() connectionProfiles: any[];

  constructor() {}

  ngOnInit(): void {}
}
