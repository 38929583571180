import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-connection-allocationmethod-history',
  templateUrl: './connection-allocationmethod-history.component.html',
  styleUrls: ['./connection-allocationmethod-history.component.scss'],
})
export class ConnectionAllocationmethodHistoryComponent implements OnInit {
  @Input() allocationMethod: any[];
  @Input() connectionInfo: any;

  constructor() {}

  ngOnInit(): void {}
}
