<div class="row row-top">
    <div class="col-sm-12">
        <span class="table-title">Klant-informatie</span>
        <table class="table-responsive table-front">
            <thead>
                <tr>
                    <th scope="col">Id</th>
                    <th>Levering vanaf</th>
                    <th>Levering tot</th>
                    <th>Klant</th>
                </tr>
            </thead>
            <tbody>
                <ng-template ngFor let-customerConnection [ngForOf]="connection.CustomerConnections" let-i="index">
                    <tr>
                        <td scope="row">{{customerConnection.Customer.Id}}</td>
                        <td>{{customerConnection?.Interval?.From?.format('YYYY-MM-DD')}}</td>
                        <td>{{customerConnection?.Interval?.Until?.format('YYYY-MM-DD')}}</td>
                        <td>{{customerConnection?.Customer?.Name}}</td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
    </div>
</div>