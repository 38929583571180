import moment from 'moment';
import {
  ClusterId,
  Connection,
  ConnectionConnectionCapacitie,
  ConnectionLocation,
  ConnectionMeter,
  CustomerConnection,
  Timestamp,
} from 'src/app/Connection';
import { ModalService } from 'src/app/modules/shared/components/modal/modal.service';
import { ConnectionService } from 'src/app/services/connection.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TranslationFromMemoryOrApiService } from 'src/app/services/translation-from-memory-or-api.service';

import { Component, Input, OnInit } from '@angular/core';

export interface ForecastType {
  value: ClusterId;
  viewValue: string;
}

@Component({
  selector: 'app-connection-info-component',
  templateUrl: './ConnectionInfoComponent.html',
  styleUrls: ['./ConnectionInfoComponent.scss'],
})
export class ConnectionInfoComponent implements OnInit {
  @Input() connection: Connection;

  from: Timestamp;
  noConnectionMeter = false;
  selectedForecastType: ClusterId;
  connectionInfo: any = {};
  connectionCustomer: any = {};
  connectionContact: any = {};
  connectionLocation: any = {};
  connectionGridOperator: any = {};
  connectionGridArea: any = {};
  connectionProfileCode: any = {};
  connectionAllocationMethod: any = {};
  hasProfileHistory: boolean;
  connectionProfileCodeHistory = [];
  hasAllocationHistory: boolean;
  connectionAllocationHistory = [];

  constructor(
    private connectionService: ConnectionService,
    private notificationService: NotificationService,
    private modalService: ModalService,
    private translation: TranslationFromMemoryOrApiService,
  ) { }

  handleTimestamp(event: any) {
    this.from = null;
    console.log(event.value);
    this.from = event.value;
  }

  async getInfoFromConnectionMeter() {
    this.connectionInfo.Ean = this.connection.Id;
    this.connectionInfo.ProductType = this.connection.ProductType;
    this.connectionInfo.ConnectionType = this.getLastConnectionCluster();
    this.connectionInfo.Capacity = this.getLastCapacity();
    this.connectionLocation.Address = this.getLastConnectionLocation() ?.Address ?? 'N/A';
    this.connectionLocation.Legislation = this.getLastConnectionLocation() ?.Legislation ?? 'N/A';
    this.connectionAllocationMethod = this.getAllocationMethod();
    this.connectionGridOperator = await this.getLastGridOperator();
    this.connectionGridArea = this.getLastGridArea();
    this.connectionProfileCode = this.getLastProfileCode();

    if (!this.connection.ConnectionLocations || this.connection.ConnectionLocations.length === 0) {
      this.notificationService.showError('Geen locatie kunnen vinden voor aansluiting');
    }

    if (this.connection.ConnectionMeters && this.connection.ConnectionMeters.length > 0) {
      const activeConnectionMeter = this.getActiveConnectionMeter();
      this.connectionInfo.activeMeter = activeConnectionMeter;
      this.connectionInfo.mId = activeConnectionMeter ?.ExternalId ?? 'N/A';
      this.connectionInfo.mIsTelemeter = activeConnectionMeter ?.Meter ?.IsTelemeter;
      this.connectionInfo.mIsOperational = activeConnectionMeter ?.TelemetryIsOperational;
      this.connectionInfo.tariffSwitch = this.getTariffSwitch() ?.TariffSwitch ?? 'N/A';
      this.connectionInfo.mMultiplicationFactor = activeConnectionMeter ?.Meter ?.MeasurementDisplays[0] ?.MultiplicationFactor ?? 'N/A';
      this.connectionInfo.currenttenant = this.getCurrentConnectionTenant();
    } else {
      this.noConnectionMeter = true;
    }
  }

  getActiveConnectionMeter(): ConnectionMeter {
    const active = this.connection.ConnectionMeters.filter(
      (c) => c.Interval.From <= moment() && (c.Interval.Until == null || c.Interval.Until > moment()),
    );
    return active.length > 0 ? active[active.length - 1] : null;
  }

  getInfoFromCurrentConnectionCustomer() {
    const lastCustomer = this.getLastCustomer();
    this.connectionCustomer.Id = lastCustomer ?.Customer ?.Id ?? 'N/A';
    this.connectionCustomer.Name = lastCustomer ?.Customer ?.Name ?? 'N/A';
    this.connectionCustomer.Tenant = lastCustomer ?.Customer ?.Tenant ?? 'N/A';
  }

  async ngOnInit() {
    await this.getInfoFromConnectionMeter();
    this.getInfoFromCurrentConnectionCustomer();
  }

  getLastConnectionCluster(): any | null {
    if (this.connection == null || this.connection.ConnectionClusters === undefined || this.connection.ConnectionClusters.length === 0) {
      return null;
    }

    const edsnMarketSegment = this.connection.ConnectionClusters.filter((ct) => ct.clusterId.clusterType === 'EdsnMarketSegment');
    return edsnMarketSegment[edsnMarketSegment.length - 1];
  }

  getSelectedClusterId(): any | null {
    if (this.connection == null) {
      return null;
    }
    const connectionCluster = this.connection.ConnectionClusters.filter((ct) => ct.clusterId.clusterType === 'DexterForecast');
    if (connectionCluster === undefined || connectionCluster.length === 0) {
      return null;
    }
    return connectionCluster[connectionCluster.length - 1].clusterId;
  }

  getCurrentConnectionTenant(): any | null {
    if (this.connection == null || this.connection.CustomerConnections.length === 0 || this.connection.ConnectionMeters.length === 0) {
      return null;
    }
    return this.connection.CustomerConnections[this.connection.CustomerConnections.length - 1].Customer.Tenant;
  }

  getLastCapacity(): ConnectionConnectionCapacitie | null {
    if (this.connection == null || this.connection.ConnectionConnectionCapacities.length === 0) {
      return null;
    }

    return this.connection.ConnectionConnectionCapacities[this.connection.ConnectionConnectionCapacities.length - 1];
  }

  getLastConnectionContacts() {
    if (this.connection == null || this.connection.ConnectionContacts == null || this.connection.ConnectionContacts.length === 0) {
      return null;
    }
    return this.connection.ConnectionContacts[this.connection.ConnectionContacts.length - 1];
  }
  getLastConnectionLocation(): ConnectionLocation | null {
    if (this.connection == null || this.connection.ConnectionLocations == null || this.connection.ConnectionLocations.length === 0) {
      return null;
    }
    return this.connection.ConnectionLocations[this.connection.ConnectionLocations.length - 1];
  }

  getLastCustomer(): CustomerConnection | null {
    if (this.connection == null || this.connection.CustomerConnections == null || this.connection.CustomerConnections.length === 0) {
      return null;
    }
    return this.connection.CustomerConnections[this.connection.CustomerConnections.length - 1];
  }

  getTariffSwitch(): any | null {
    if (this.connection == null || this.connection.ConnectionTariffSwitches.length === 0) {
      return null;
    }
    return this.connection.ConnectionTariffSwitches[this.connection.ConnectionTariffSwitches.length - 1];
  }

  getLastGridOperator(): any | null {
    if (this.connection == null || this.connection.ConnectionGridOperators == null || this.connection.ConnectionGridOperators.length === 0) {
      return null;
    }

    let gridOperator = this.connection.ConnectionGridOperators[this.connection.ConnectionGridOperators.length - 1];

    const gridOperatorTranslated = this.translation.getTranslation(
      'Frontend',
      'Netbeheerders',
      gridOperator.gridOperatorId,
    );

    return { grid: gridOperatorTranslated, date: gridOperator.interval.From };
  }

  getLastGridArea(): any | null {
    if (this.connection == null || this.connection.ConnectionGridAreas == null || this.connection.ConnectionGridAreas.length === 0) {
      return null;
    }

    let area = this.connection.ConnectionGridAreas[this.connection.ConnectionGridAreas.length - 1];

    return { id: area.gridAreaId, date: area.interval.From };
  }

  getLastProfileCode(): any | null {
    const arrayProfiles = this.connection.ConnectionProfiles;
    this.connectionProfileCodeHistory = this.connection.ConnectionProfiles;

    if (arrayProfiles.length > 1) {
      this.hasProfileHistory = true;
    } else {
      this.hasProfileHistory = false;
    }

    if (this.connection == null || arrayProfiles == null || arrayProfiles.length === 0) {
      return null;
    }

    let profileCode;
    let profileDate;

    arrayProfiles.forEach((x) => {
      if (x.Interval.Until == null) {
        profileCode = x.ProfileId;
        profileDate = x.Interval.From;
      }
    });

    return { code: profileCode, date: profileDate };
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  getAllocationMethod() {
    const arrayAllocationMethod = this.connection.ConnectionAllocationMethods;

    this.hasAllocationHistory = arrayAllocationMethod.length > 1;
    this.connectionAllocationHistory = arrayAllocationMethod;
    let allocationMethod;
    let fromDate;

    arrayAllocationMethod.forEach((x) => {
      if (x.interval.Until == null) {
        allocationMethod = x.allocationMethod;
        fromDate = x.interval.From;
      }
    });

    if (this.connection == null || this.connection.ConnectionAllocationMethods == null || this.connection.ConnectionAllocationMethods.length === 0) {
      return null;
    } else if (this.connectionInfo.ConnectionType ?.clusterId ?.name === 'KV' && allocationMethod === 'Telemetric') {
      allocationMethod = 'SMA';
    }

    return { method: allocationMethod, date: fromDate }
  }

  updateConnectionWithLatestMarketData() {
    const tenant = this.getLastCustomer().Customer.Tenant;
    this.connectionService.updateConnectionWithLatestMarketData(this.connection.Id, tenant);

    const icon = document.getElementById('icon');

    if (icon != null) {
      icon.style.color = 'lightgrey';
    }
  }
}
