<div class="card-body" *ngIf="connection">

  <div class="container-fluid">
    <div class="row">

      <div class="col-sm-8">
        <div class="card h-100">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12">
                <h5>Aansluiting overzicht
                  <mat-icon class="maticon-button-table maticon-meter" matTooltip="stamdata update" (click)="updateConnectionWithLatestMarketData()">
                    <span id="icon">update</span>
                  </mat-icon>
                </h5>
                <div class="row">
                  <div class="col-8 col-sm-6">
                    <p>
                      <span class="span-title">EAN:</span>
                      <span>{{connectionInfo.Ean}}</span><br />
                      <span class="span-title">Product:</span>
                      <span>{{connectionInfo.ProductType}}</span><br />
                      <span class="span-title">Type aansluiting:</span>
                      <span>{{connectionInfo.ConnectionType?.clusterId.name ?? 'N/A'}}</span><br />
                      <span class="span-title">Capaciteit:</span>
                      <span matTooltip="{{connectionInfo.Capacity?.Interval?.From | date: 'dd-MM-yyyy'}}">{{connectionInfo.Capacity?.ConnectionCapacity
                        ?? 'N/A'}}</span>
                      <br />
                      <span class="span-title">Allocatie Methode:</span>
                      <span *ngIf="hasAllocationHistory" class="ns-link" (click)="openModal(connectionAllocationMethod)" matTooltip="{{connectionAllocationMethod?.date | date: 'dd-MM-yyyy'}}">{{connectionAllocationMethod?.method
                        ?? 'N/A' }}
                      </span>
                      <span *ngIf="!hasAllocationHistory" matTooltip="{{connectionAllocationMethod?.date | date: 'dd-MM-yyyy'}}">{{connectionAllocationMethod?.method
                        ?? 'N/A'}}</span>
                      <br />
                      <ng-container *ngIf="connectionLocation?.Address">
                        <span class="span-title">Stad:</span>
                        <span>{{connectionLocation.Address.City}}</span><br />
                        <span class="span-title">Postcode:</span>
                        <span>{{connectionLocation.Address.Postcode}}</span><br />
                        <span class="span-title">Adres:</span>
                        <span>{{connectionLocation.Address.Street +" "+ connectionLocation.Address.HouseNumber}}
                        </span>
                        <span *ngIf="connectionLocation.Address.HouseNumberSuffix">{{connectionLocation.Address.HouseNumberSuffix}}
                        </span>
                        <span *ngIf="connectionLocation.Address.LocationOnStreet">{{connectionLocation.Address.LocationOnStreet}}</span>
                      </ng-container>
                    </p>
                  </div>
                  <div class="col-4 col-sm-6">
                    <p>
                      <span class="span-title">Netbeheerder:</span>
                      <span matTooltip="{{connectionGridOperator?.date | date: 'dd-MM-yyyy'}}">{{connectionGridOperator?.grid
                        ?? 'N/A' | translation:'Netbeheerders' }}</span>
                      <br />
                      <span class="span-title">Netgebied:</span>
                      <span matTooltip="{{connectionGridArea?.date | date: 'dd-MM-yyyy'}}">{{connectionGridArea?.id ?? 'N/A'}}</span><br
                      />
                      <ng-container *ngIf="noConnectionMeter">
                        <br />
                        <span class="span-title">Geen meter voor deze aansluiting.</span><br />
                      </ng-container>
                      <ng-container *ngIf="!noConnectionMeter">
                        <span class="span-title">P4 (slimme meter):</span>
                        <span matTooltip="{{connectionInfo.activeMeter?.Interval?.From | date: 'dd-MM-yyyy'}}">{{connectionInfo.mIsTelemeter
                          !== undefined ? (connectionInfo.mIsTelemeter ? 'Ja' : 'Nee') : 'N.v.t'}}</span> <br />
                        <span class="span-title">Actief:</span>
                        <span matTooltip="{{connectionInfo.activeMeter?.Interval?.From | date: 'dd-MM-yyyy'}}">{{connectionInfo.mIsOperational
                          !== undefined ? (connectionInfo.mIsOperational ? 'Ja' : 'Nee') : 'N.v.t'}}</span> <br />
                        <span class="span-title">MultiplicationFactor:</span>
                        <span>{{connectionInfo.mMultiplicationFactor}}</span> <br />
                        <span class="span-title">Huidig MeterID:</span>
                        <span matTooltip="{{connectionInfo.activeMeter?.Interval?.From | date: 'dd-MM-yyyy'}}">{{connectionInfo.mId}}</span>
                        <br/>
                        <span class="span-title">Profielcode:</span>
                        <span *ngIf="hasProfileHistory" class="ns-link" (click)="openModal(connectionProfileCode)" matTooltip="{{connectionProfileCode?.date | date: 'dd-MM-yyyy'}}">{{connectionProfileCode?.code
                          ?? 'N/A'}}
                        </span>
                        <span *ngIf="!hasProfileHistory" matTooltip="{{connectionProfileCode?.date | date: 'dd-MM-yyyy'}}">{{connectionProfileCode?.code
                          ?? 'N/A'}}</span> <br />
                        <span class="span-title">Tarief Overgang:</span>
                        <span>{{connectionInfo.tariffSwitch}}</span> <br />
                      </ng-container>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="card h-100">
          <div class="card-body">
            <h5>Klant informatie</h5>
            <p>
              <span class="span-title">Leverancier:</span>
              <span>{{connectionCustomer.Tenant}}</span><br />
              <span class="span-title">Klantnummer:</span>
              <span>{{connectionCustomer.Id}}</span><br />
              <span class="span-title">Naam:</span>
              <span>{{connectionCustomer.Name}}</span><br />
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
    <br />

    <div class="card h-100">
      <div class="card-body">
        <h5>Aansluiting details</h5>
        <div class="row">
          <div class="col-md-12">
            <app-connection-meter-list [connectionMeters]="connection.ConnectionMeters">
            </app-connection-meter-list>

          </div>

          <div class="col-md-6">
            <app-connection-physical-state-list [connectionPhysicalStates]="connection.ConnectionPhysicalStates">
            </app-connection-physical-state-list>
          </div>
          <div class="col-md-6">
            <app-connection-capacity-list [connectionCapacities]="connection.ConnectionConnectionCapacities">
            </app-connection-capacity-list>
          </div>
          <div class="col-md-6">
            <app-connection-type-list [connectionSupplyTypes]="connection.ConnectionSupplyTypes">
            </app-connection-type-list>
          </div>
          <div class="col-md-6">
            <app-connection-estimated-annual-prosumption-list [connectionProductType]="connection.ProductType" [connectionEstimatedAnnualProsumptions]="connection.ConnectionEstimatedAnnualProsumptions">
            </app-connection-estimated-annual-prosumption-list>
          </div>
        </div>





      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <customers-list-component [connection]="connection"></customers-list-component>
      </div>
    </div>
  </div>
</div>

<jw-modal [id]="connectionProfileCode">
  <app-connection-profilecode-history [connectionProfiles]="connectionProfileCodeHistory">
  </app-connection-profilecode-history>
</jw-modal>

<jw-modal [id]="connectionAllocationMethod">
  <app-connection-allocationmethod-history [allocationMethod]="connectionAllocationHistory" [connectionInfo]="connectionInfo.ConnectionType">
  </app-connection-allocationmethod-history>
</jw-modal>